@font-face {
  font-family: 'Akzidenz Grotesk';
  src: url(src/fonts/AkzidenzGroteskBQ-Reg.eot?#iefix);
  src: url(src/fonts/AkzidenzGroteskBQ-Reg.eot?#iefix) format("embedded-opentype"), url(src/fonts/AkzidenzGroteskBQ-Reg.otf) format("opentype"), url(src/fonts/AkzidenzGroteskBQ-Reg.woff) format("woff"), url(src/fonts/AkzidenzGroteskBQ-Reg.ttf) format("truetype"), url(src/fonts/AkzidenzGroteskBQ-Reg.svg#AkzidenzGroteskBQ-Reg) format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Akzidenz Grotesk';
  src: url(src/fonts/AkzidenzGroteskBQ-Medium.eot?#iefix);
  src: url(src/fonts/AkzidenzGroteskBQ-Medium.eot?#iefix) format("embedded-opentype"), url(src/fonts/AkzidenzGroteskBQ-Medium.otf) format("opentype"), url(src/fonts/AkzidenzGroteskBQ-Medium.woff) format("woff"), url(src/fonts/AkzidenzGroteskBQ-Medium.ttf) format("truetype"), url(src/fonts/AkzidenzGroteskBQ-Medium.svg#AkzidenzGroteskBQ-Medium) format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Akzidenz Grotesk';
  src: url(src/fonts/AkzidenzGroteskBQ-Bold.eot?#iefix);
  src: url(src/fonts/AkzidenzGroteskBQ-Bold.eot?#iefix) format("embedded-opentype"), url(src/fonts/AkzidenzGroteskBQ-Bold.otf) format("opentype"), url(src/fonts/AkzidenzGroteskBQ-Bold.woff) format("woff"), url(src/fonts/AkzidenzGroteskBQ-Bold.ttf) format("truetype"), url(src/fonts/AkzidenzGroteskBQ-Bold.svg#AkzidenzGroteskBQ-Bold) format("svg");
  font-weight: 700;
  font-style: normal; }

*,
*::before,
*::after {
  box-sizing: border-box; }

main {
  display: block; }

body {
  margin: 0;
  font-family: "Akzidenz Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  text-align: left;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.9rem;
  font-weight: 500;
  line-height: 1.2; }

h1 {
  font-size: 2.5rem; }

h2 {
  font-size: 2rem; }

h3 {
  font-size: 1.75rem; }

h4 {
  font-size: 1.5rem; }

h5 {
  font-size: 1.25rem; }

h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 2rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #000;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #262626;
    text-decoration: none; }

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container, .text-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, .text-container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, .text-container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, .text-container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container, .text-container {
      max-width: 1140px; } }
  @media (min-width: 1440px) {
    .container, .text-container {
      max-width: 1400px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.outline-title {
  letter-spacing: -0.016em;
  font-weight: 700;
  color: transparent;
  text-transform: uppercase;
  text-stroke: 2.3px #000;
  -webkit-text-stroke: 2.3px #000;
  font-size: 160px;
  font-size: calc(130px + 2vw);
  line-height: 0.95; }
  @media (max-width: 991.98px) {
    .outline-title {
      font-size: 90px;
      font-size: calc(50px + 6vw);
      text-stroke-width: 2px;
      -webkit-text-stroke-width: 2px;
      line-height: 0.9; } }

.outline-subtitle {
  letter-spacing: -0.016em;
  font-weight: 700;
  color: transparent;
  text-transform: uppercase;
  text-stroke: 2.3px #000;
  -webkit-text-stroke: 2.3px #000;
  font-size: 85px; }
  @media (max-width: 991.98px) {
    .outline-subtitle {
      font-size: 68px;
      text-stroke-width: 1.5px;
      -webkit-text-stroke-width: 1.5px; } }
  @media (max-width: 767.98px) {
    .outline-subtitle {
      font-size: 55px; } }

a.outline-subtitle {
  transition: all 150ms ease-in; }
  a.outline-subtitle:hover {
    transition-timing-function: ease-out;
    color: transparent;
    text-decoration: none;
    -webkit-text-stroke-color: #76ff41;
    text-stroke-color: #76ff41; }

.p0 {
  font-size: 2.5rem;
  line-height: 1;
  text-transform: uppercase; }
  @media (max-width: 767.98px) {
    .p0 {
      font-size: 2.125rem; } }

.p1 {
  font-size: 1.25rem;
  line-height: 1.4;
  text-transform: uppercase; }

.p2 {
  font-size: 0.875rem;
  line-height: 1.225;
  text-transform: uppercase; }

.p3 {
  font-size: 0.78rem;
  line-height: 1.75;
  text-transform: uppercase; }

.btn {
  display: inline-block;
  position: relative;
  font-family: "Akzidenz Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  color: #000;
  text-align: left;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  padding: 10px 80px 10px 20px;
  font-size: 1.25rem;
  line-height: 1.4;
  text-transform: uppercase;
  border-radius: 0; }
  .btn, .btn:before, .btn:after {
    transition: all 180ms ease; }
  .btn:before, .btn:after {
    content: '';
    position: absolute; }
  .btn:before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 2px solid #000; }
  .btn:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    height: 16px;
    width: 30px;
    background-image: url(src/images/arrow-right.png);
    background-size: cover;
    transform: translateY(-50%); }
  .btn:hover {
    color: #000;
    text-decoration: none;
    box-shadow: -5px 6px 0 0px #fff, -7px 8px 0px 0px #000, -3px 8px 0px 0px #000, -7px 4px 0px 0px #000; }
    .btn:hover:after {
      transform: translate(30px, -50%); }
    .btn:hover, .btn:hover:before, .btn:hover:after {
      transition-duration: 100ms; }
  .btn:focus, .btn.focus {
    outline: 0; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }

.btn--block {
  width: 100%; }
  .btn--block + .btn--block {
    margin-top: 15px; }

header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 0; }
  header a {
    position: relative;
    z-index: 1; }
    header a:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: -3px;
      bottom: -3px;
      left: -10px;
      right: -10px;
      background-color: #fff;
      opacity: 0;
      border-radius: 50px; }
    header a:hover {
      color: #76ff41; }
      header a:hover:before {
        opacity: 1; }
  header nav {
    margin-top: 10px;
    text-align: center; }
    @media (min-width: 768px) {
      header nav {
        margin-top: 0;
        text-align: right; } }
    header nav ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      header nav ul li {
        display: inline-block;
        margin: 0 6px;
        padding: 0 3px; }
        @media (min-width: 768px) {
          header nav ul li:first-child {
            margin-left: 0;
            padding-left: 0; }
          header nav ul li:last-child {
            margin-right: 0;
            padding-right: 0; } }

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }
  @media (min-width: 768px) {
    .header-content {
      justify-content: space-between;
      flex-direction: row;
      text-align: left; } }

@keyframes footer-banner-animation {
  0% {
    transform: translateX(-50%); }
  100% {
    transform: translateX(0%); } }

footer {
  padding: 60px 0 0;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.225;
  text-transform: uppercase; }
  footer a {
    position: relative;
    z-index: 1; }
    footer a:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: -3px;
      bottom: -3px;
      left: -10px;
      right: -10px;
      background-color: #fff;
      opacity: 0;
      border-radius: 50px; }
    footer a:hover {
      color: #76ff41; }
      footer a:hover:before {
        opacity: 1; }
  footer ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 600px;
    margin: 0 auto 30px; }
    @media (min-width: 576px) {
      footer ul {
        flex-direction: row; } }
    footer ul li + li {
      margin-top: 7px; }
      @media (min-width: 576px) {
        footer ul li + li {
          margin-top: 0; } }

.footer-banner {
  padding: 10px 0;
  background-color: #000;
  margin-top: 45px; }
  @media (min-width: 768px) {
    .footer-banner {
      margin-top: 60px;
      padding: 15px 0; } }
  .footer-banner:after {
    content: '';
    display: block;
    height: 50px;
    height: calc(30px + 5vw);
    width: 200%;
    background-size: 8.33333% auto;
    background-repeat: repeat-x;
    background-position: 0% center;
    background-image: url(src/images/logo-small-inverted.jpg);
    animation: footer-banner-animation 15s linear infinite; }
    @media (min-width: 768px) {
      .footer-banner:after {
        animation-duration: 30s;
        background-size: 6.25% auto;
        background-size: calc(6.25vw) auto;
        height: 100px;
        height: calc(30px + 2vw); } }

.tag-wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.tag {
  position: relative;
  top: -9vh;
  max-width: 95%;
  margin: 0 auto;
  font-size: 27px;
  line-height: 1.1;
  text-transform: uppercase; }
  @media (min-width: 400px) {
    .tag {
      font-size: 36px; } }
  @media (min-width: 576px) {
    .tag {
      font-size: 53px; } }
  @media (min-width: 992px) {
    .tag {
      top: -5vh;
      font-size: 72px;
      max-width: 85vw;
      margin: 0 auto; } }
  @media (min-width: 1200px) {
    .tag {
      font-size: 91px;
      max-width: 1200px;
      line-height: 105px; } }

@media (min-width: 768px) {
  .tag-row {
    display: flex; } }

.tag-row.tag-row--v {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.tag-row .plus {
  display: inline-block;
  font-size: 70%;
  margin: 0 13px;
  -webkit-text-stroke-width: 1px;
  text-stroke-width: 1px; }

.tag-swap {
  flex: 1;
  position: relative;
  text-align: right;
  margin-top: 30px; }
  @media (min-width: 768px) {
    .tag-swap {
      margin-top: 0; } }
  .tag-swap:after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 3px;
    right: 3px;
    height: 3px;
    background-color: #000; }
    @media (min-width: 576px) {
      .tag-swap:after {
        left: 20px;
        right: 6px;
        bottom: -8px;
        height: 4px; } }
    @media (min-width: 992px) {
      .tag-swap:after {
        height: 6px; } }

.tag-line {
  display: inline-block; }

.tag-line-gallery-container {
  position: absolute;
  z-index: -1;
  opacity: 0.85;
  top: 20vh;
  bottom: 20vh;
  right: 5%;
  left: 20%; }
  @media (min-width: 576px) {
    .tag-line-gallery-container {
      top: 25%;
      bottom: 25%;
      right: 10%;
      left: 30%; } }
  @media (min-width: 992px) {
    .tag-line-gallery-container {
      top: 17%;
      bottom: 7%;
      right: 14%; } }
  @media (min-width: 1200px) {
    .tag-line-gallery-container {
      left: 40%;
      right: 20%; } }

.tag-line-gallery {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .tag-line-gallery img {
    -o-object-fit: contain;
    object-fit: contain;
    height: 100%;
    width: 100%;
    position: absolute;
    /* top: 0; */
    /* right: 0; */
    /* left: 0; */
    /* bottom: 0; */
    opacity: 0; }
    .tag-line-gallery img.is-active {
      opacity: 1; }

.clients {
  max-width: 950px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  @media (min-width: 768px) {
    .clients {
      flex-direction: row; } }

.client-list {
  display: inline-block;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  list-style: none;
  font-size: 1.25rem;
  line-height: 1.55;
  text-align: center; }
  .client-list li {
    cursor: pointer; }
    .client-list li.is-active {
      color: #f432ca; }
  @media (min-width: 768px) {
    .client-list {
      text-align: left; }
      .client-list:nth-child(1) {
        order: 1;
        text-align: center; }
      .client-list:nth-child(3) {
        order: 2;
        text-align: right; } }

.client-banner-wrap {
  height: 120px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #113CFA;
  transform: translateY(-50%); }

@keyframes banner-animation {
  0% {
    background-position: 0% center; }
  100% {
    background-position: -100% center; } }

.client-banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: auto 120%;
  background-repeat: repeat-x;
  background-position: 0% center;
  background-color: #fff;
  animation: banner-animation 5s linear infinite;
  z-index: -1;
  mix-blend-mode: screen; }
  .client-banner--nike {
    background-image: url(src/images/client-logos/nike.jpg);
    background-size: auto 116%; }
  .client-banner--adidas {
    background-image: url(src/images/client-logos/adidas.jpg); }
  .client-banner--red-bull {
    background-image: url(src/images/client-logos/red-bull.jpg); }
  .client-banner--dc-shoe-co {
    background-image: url(src/images/client-logos/dc-shoe-co.jpg); }
  .client-banner--monster {
    background-image: url(src/images/client-logos/monster.jpg); }
  .client-banner--obey {
    background-image: url(src/images/client-logos/obey.jpg);
    background-size: auto 130%; }
  .client-banner--vans {
    background-image: url(src/images/client-logos/vans.jpg); }
  .client-banner--undefeated {
    background-image: url(src/images/client-logos/undefeated.jpg); }
  .client-banner--stance {
    background-image: url(src/images/client-logos/stance.jpg); }
  .client-banner--mountain-dew {
    background-image: url(src/images/client-logos/mountain-dew.jpg); }
  .client-banner--lagunitas-brewing {
    background-image: url(src/images/client-logos/lagunitas-brewing.jpg); }
  .client-banner--oakley {
    background-image: url(src/images/client-logos/oakley.jpg); }
  .client-banner--element {
    background-image: url(src/images/client-logos/element.jpg); }
  .client-banner--huf {
    background-image: url(src/images/client-logos/huf.jpg); }
  .client-banner--hbo {
    background-image: url(src/images/client-logos/hbo.jpg); }
  .client-banner--cinemax {
    background-image: url(src/images/client-logos/cinemax.jpg); }
  .client-banner--marmot {
    background-image: url(src/images/client-logos/marmot.jpg); }
  .client-banner--fox-racing {
    background-image: url(src/images/client-logos/fox-racing.jpg); }
  .client-banner--fitbit {
    background-image: url(src/images/client-logos/fitbit.jpg); }
  .client-banner--fueltv {
    background-image: url(src/images/client-logos/fueltv.jpg); }
  .client-banner--monster-children {
    background-image: url(src/images/client-logos/monster-children.jpg); }
  .client-banner--ray-ban {
    background-image: url(src/images/client-logos/rayban.jpg); }
  .client-banner--apple {
    background-image: url(src/images/client-logos/apple.jpg); }
  @media (min-width: 768px) {
    .client-banner {
      animation-duration: 10s; } }
  @media (min-width: 1200px) {
    .client-banner {
      animation-duration: 20s; } }

.artist-list {
  display: inline-block;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  list-style: none;
  font-size: 1.25rem;
  line-height: 1.55; }
  .artist-list li {
    position: relative; }

.artist-link {
  display: inline-block; }
  .artist-link:after {
    content: '';
    display: inline-block;
    margin-left: 10px;
    opacity: 0;
    height: 14px;
    width: 26px;
    background-image: url(src/images/arrow-right.png);
    background-size: cover; }
  .artist-link:hover, .artist-link:focus {
    text-decoration: none; }
  .artist-link.is-active {
    color: #f432ca; }
    .artist-link.is-active:after {
      opacity: 1; }
  @media (max-width: 767.98px) {
    .artist-link {
      padding: 2.5vh 0; } }

.artist-portrait {
  height: 75vw;
  width: 75vw;
  max-height: 600px;
  max-width: 600px;
  position: fixed;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  opacity: 0;
  z-index: -1;
  transition: opacity 120ms ease 120ms; }
  @media (min-width: 768px) {
    .artist-portrait {
      height: 50vw;
      width: 50vw;
      position: absolute;
      left: 0;
      right: auto;
      transform: translate(25%, -50%);
      transition-duration: 0ms;
      transition-delay: 0ms; } }
  .artist-link.is-active .artist-portrait {
    opacity: 1;
    transition-delay: 0ms; }

.portrait-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  mix-blend-mode: overlay;
  filter: brightness(1.5); }
  .portrait-image--mndsgn {
    background-image: url(src/images/artists/mndsgn.jpg); }
  .portrait-image--low-leaf {
    background-image: url(src/images/artists/low-leaf.jpg); }
  .portrait-image--odd-nosdam {
    background-image: url(src/images/artists/odd-nosdam.jpg); }
  .portrait-image--eyedress {
    background-image: url(src/images/artists/eyedress.jpg); }
  .portrait-image--huxley-anne {
    background-image: url(src/images/artists/huxley-anne.jpg); }
  .portrait-image--free-the-robots {
    background-image: url(src/images/artists/free-the-robots.jpg); }
  .portrait-image--kenny-segal {
    background-image: url(src/images/artists/kenny-segal.jpg); }
  .portrait-image--the-heavy-twelves {
    background-image: url(src/images/artists/the-heavy-twelves.jpg); }
  .portrait-image--dj-nobody {
    background-image: url(src/images/artists/dj-nobody.jpg); }
  .portrait-image--mophono {
    background-image: url(src/images/artists/mophono.jpg); }
  .portrait-image--jel {
    background-image: url(src/images/artists/jel.jpg); }
  .portrait-image--great-dane {
    background-image: url(src/images/artists/great-dane.jpg); }
  .portrait-image--afta-1 {
    background-image: url(src/images/artists/afta-1.jpg); }
  .portrait-image--salami-rose {
    background-image: url(src/images/artists/salami-rose.jpg); }
  .portrait-image--lealani {
    background-image: url(src/images/artists/lealani.jpg); }

@keyframes globe-rotate {
  0% {
    transform: rotate3d(1, 1, 1, 0deg); }
  100% {
    transform: rotate3d(1, 1, 1, 360deg); } }

.globe-wrapper {
  width: 140%;
  margin: 0 auto;
  position: absolute;
  z-index: -1; }
  @media (min-width: 576px) {
    .globe-wrapper {
      max-width: 600px; } }
  @media (min-width: 992px) {
    .globe-wrapper {
      width: 48vw;
      max-width: 640px; } }
  .js .globe-wrapper {
    transform: scale(0.65);
    transition: transform 5s cubic-bezier(0.36, 0.29, 0, 1.51); }
  .js .globe-area.is-visible .globe-wrapper {
    transform: scale(1); }

.globe-fallback {
  background-image: url(src/images/globe.jpg);
  background-size: cover;
  height: 0;
  width: 100%;
  padding-bottom: 102.4%;
  margin: 0 auto;
  position: relative;
  animation: globe-rotate 10s linear infinite; }

.globe-video {
  width: 100%;
  margin: 0 auto; }

.globe-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 200px;
  padding: 100px 0 50%; }
  @media (min-width: 768px) {
    .globe-area {
      min-height: 80vh;
      margin-top: 100px;
      padding-bottom: 100px; } }

.globe-text {
  text-align: center;
  max-width: 10.5em;
  margin: 0 auto;
  font-size: 2.5rem;
  line-height: 1;
  text-transform: uppercase; }
  @media (max-width: 767.98px) {
    .globe-text {
      font-size: 2.125rem; } }
  @media (min-width: 768px) {
    .globe-text {
      max-width: 21em; } }

.catalog-holder {
  border: 1px solid #DDD;
  border-width: 1px 0;
  overflow: hidden; }

.catalog-embed {
  max-width: 1210px;
  margin-left: auto;
  margin-right: auto;
  min-height: 75vh; }
  .catalog-embed > iframe {
    width: 100%;
    height: 100%;
    min-height: 75vh;
    vertical-align: top; }
  @media (min-width: 768px) {
    .catalog-embed {
      height: 75vh; } }

body.music-supervision .container, body.music-supervision .text-container {
  max-width: 1130px; }

body.music-supervision .closing,
body.music-supervision footer {
  background-color: #40FA19; }

body.music-supervision .closing .outline-subtitle:hover {
  -webkit-text-stroke-color: #fff;
  text-stroke-color: #fff; }

body.music-supervision .closing .btn {
  background-color: #fff; }

section.sprvsn-intro {
  margin-top: 0;
  padding-top: 36vh !important;
  padding-bottom: 24vh;
  overflow: visible; }
  @media (min-width: 768px) {
    section.sprvsn-intro {
      padding-top: 27vh;
      padding-bottom: 15vh; } }
  section.sprvsn-intro .sprvsn-heading {
    display: flex;
    flex-direction: column;
    margin: 30px 0 220px; }
  section.sprvsn-intro .sprvsn-sub {
    text-transform: none;
    margin-bottom: 40px; }
    @media (min-width: 576px) {
      section.sprvsn-intro .sprvsn-sub br {
        display: none; } }
    @media (min-width: 1200px) {
      section.sprvsn-intro .sprvsn-sub {
        transform: translateX(-50px);
        margin-bottom: 90px; } }
    @media (min-width: 1440px) {
      section.sprvsn-intro .sprvsn-sub {
        transform: translateX(-125px);
        transform: translateX(-8vw); } }
  section.sprvsn-intro .sprvsn-publishers .sprvsn-text {
    display: block;
    margin: 5px 0; }
  @media (min-width: 576px) {
    section.sprvsn-intro .sprvsn-publishers {
      display: flex;
      align-items: baseline; }
      section.sprvsn-intro .sprvsn-publishers .sprvsn-text {
        margin: 0 0 0 50px; } }

@keyframes circle-spin {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

.sprvsn-intro__background {
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .sprvsn-intro__background .sprvsn-dots {
    position: absolute;
    display: inline-block;
    pointer-events: none; }
    .sprvsn-intro__background .sprvsn-dots.sprvsn-dots--1 {
      bottom: 8vh;
      left: 3vw; }
    .sprvsn-intro__background .sprvsn-dots.sprvsn-dots--2 {
      bottom: 48vh;
      left: 29vw;
      transform: rotate(90deg);
      display: none; }
      @media (min-width: 992px) {
        .sprvsn-intro__background .sprvsn-dots.sprvsn-dots--2 {
          display: block; } }
  .sprvsn-intro__background .sprvsn-circles {
    position: absolute;
    z-index: -1;
    top: 23vh;
    right: 0;
    transform: translateX(35%);
    width: 110vw;
    opacity: 0;
    transition: opacity 5s ease-out; }
    @media (min-width: 992px) {
      .sprvsn-intro__background .sprvsn-circles {
        top: 10vh;
        width: 1080px; } }
    .sprvsn-intro__background .sprvsn-circles img {
      width: 100%;
      filter: contrast(1.5) saturate(10);
      animation: circle-spin 20s linear infinite; }
  body.is-loaded .sprvsn-intro__background .sprvsn-circles {
    opacity: 1; }

section.sprvsn-highlight {
  margin-top: 0;
  padding: 180px 0 50px;
  background-color: #40FA19;
  overflow: visible; }
  @media (min-width: 768px) {
    section.sprvsn-highlight {
      margin-top: 140px;
      padding: 140px 0; } }
  @media (min-width: 992px) {
    section.sprvsn-highlight {
      margin-top: 160px;
      padding: 260px 0 140px; } }

.sprvsn-arrow {
  position: relative;
  display: inline-block;
  height: 3px;
  width: 100%;
  background-color: #fff; }
  .sprvsn-arrow:after {
    content: '';
    height: 15px;
    width: 15px;
    border: 3px solid white;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(45deg) translate(4px, 5px);
    border-width: 3px 3px 0 0; }

.sprvsn-double-arrow {
  transform: rotate(90deg) translate(-105px, 61px);
  width: 150px;
  position: absolute; }
  @media (min-width: 768px) {
    .sprvsn-double-arrow {
      transform: rotate(90deg) translate(-15px, 107px);
      width: 250px; } }
  @media (min-width: 992px) {
    .sprvsn-double-arrow {
      transform: rotate(90deg) translate(-60px, 124px);
      width: 400px; } }
  .sprvsn-double-arrow .sprvsn-arrow {
    width: 50%;
    position: absolute; }
    .sprvsn-double-arrow .sprvsn-arrow:last-child {
      transform: scaleX(-1) translateX(100%);
      background-color: #000; }
      .sprvsn-double-arrow .sprvsn-arrow:last-child:after {
        border-color: #000; }

.sprvsn-arrow-wrap-1 .sprvsn-arrow {
  position: absolute;
  width: 47vw;
  max-width: 430px;
  background: white;
  transform: translate(-96%, 14px) rotate(-45deg);
  left: 0;
  bottom: 0;
  transform-origin: right; }
  .sprvsn-arrow-wrap-1 .sprvsn-arrow:after {
    right: auto;
    left: 0;
    border-width: 0 0 3px 3px; }
  @media (min-width: 768px) {
    .sprvsn-arrow-wrap-1 .sprvsn-arrow {
      width: 340px;
      transform: translate(-99%, -10px) rotate(-45deg); } }
  @media (min-width: 992px) {
    .sprvsn-arrow-wrap-1 .sprvsn-arrow {
      width: 42vw;
      transform: translate(-105%, 10px) rotate(-45deg); } }

.sprvsn-arrow-wrap-2 {
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 60px;
  margin-right: clamp(40px, 5vw, 100px); }

.sprvsn-text {
  text-transform: uppercase; }
  .sprvsn-text.sm {
    font-size: 18px;
    line-height: 1.2; }
    @media (min-width: 768px) {
      .sprvsn-text.sm {
        font-size: 22px;
        line-height: 1; } }
  .sprvsn-text.smd {
    font-size: 23px;
    letter-spacing: -0.01em; }
    @media (min-width: 768px) {
      .sprvsn-text.smd {
        font-size: 28px;
        line-height: 1; } }
  .sprvsn-text.md {
    font-size: 30px;
    line-height: 114%; }
    @media (min-width: 576px) {
      .sprvsn-text.md {
        font-size: 34px; } }
    @media (min-width: 768px) {
      .sprvsn-text.md {
        font-size: 55px; } }
  .sprvsn-text.lg {
    font-size: 40px;
    line-height: 1;
    letter-spacing: -0.01em; }
    @media (min-width: 576px) {
      .sprvsn-text.lg {
        font-size: 55px; } }
    @media (min-width: 768px) {
      .sprvsn-text.lg {
        font-size: 88px; } }
    @media (min-width: 992px) {
      .sprvsn-text.lg {
        font-size: 100px; } }
  .sprvsn-text.xl {
    font-size: 55px;
    letter-spacing: -0.01em;
    line-height: 0.95; }
    .sprvsn-text.xl.spread {
      line-height: 1; }
    .sprvsn-text.xl.spr-spread {
      line-height: 1.15; }
    @media (min-width: 576px) {
      .sprvsn-text.xl {
        font-size: 65px; } }
    @media (min-width: 768px) {
      .sprvsn-text.xl {
        font-size: 80px; } }
    @media (min-width: 992px) {
      .sprvsn-text.xl {
        font-size: 120px;
        line-height: 0.85; } }

.closing {
  text-align: center; }
  .closing .pretext,
  .closing .posttext {
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .closing .pretext,
      .closing .posttext {
        font-size: 1.25rem; } }
  .closing .pretext {
    margin-bottom: 20px; }
  .closing .posttext {
    margin-top: 35px; }
  .closing .outline-subtitle {
    display: block; }

.closing-buttons {
  max-width: 340px;
  margin: 0 auto; }

.text-center {
  text-align: center; }

.text-uppercase {
  text-transform: uppercase; }

.fs-sm {
  font-size: 0.875rem; }

.fs-lg {
  font-size: 1.25rem; }

@media (max-width: 767.98px) {
  .hide-on-mobile {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .hide-on-tablet {
    display: none !important; } }

@media (min-width: 992px) {
  .hide-on-desktop {
    display: none !important; } }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.d-inline-block {
  display: inline-block; }

.d-flex {
  display: flex; }

.js body {
  opacity: 0; }
  .js body.is-loaded {
    opacity: 1;
    transition: opacity 0.6s cubic-bezier(0.29, 0.24, 0.11, 1.11); }

section {
  position: relative;
  padding: 15vh 0;
  overflow: hidden; }
  section:first-of-type {
    padding-top: 0; }

.page-header {
  max-width: 1800px;
  margin: 15vh auto 8vh;
  transform: translateX(-15px); }
  @media (min-width: 768px) {
    .page-header {
      margin-bottom: 15vh; } }

.section-header {
  max-width: 1800px;
  margin: 0 auto;
  margin-bottom: 80px;
  transform: translateX(-15px); }
  @media (min-width: 768px) {
    .section-header {
      margin-bottom: 150px; } }
  .section-header--pull {
    transform: translateX(-10px); }

section.fs {
  min-height: 100vh; }

.text-container {
  max-width: 45em;
  margin: 0 auto; }
  .text-container p {
    margin-bottom: 1rem; }
  .text-container p + h6 {
    margin-top: 3rem; }
